@import-normalize; /* bring in normalize.css styles */

/* rest of app styles */
body {
  font-family: Arial, Helvetica, sans-serif;
  color: #555;
}

img {
  max-width: 100%;
}

.app {
  &__container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__header {
    text-align: center;
    display: none;
  }

  &__logo {
    max-width: 300px;
    margin: 0 auto;
  }
}